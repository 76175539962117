import cx from 'classnames';

export default function HeroTitleLeftOrRight({ children, isPost, isBasicHeader, headerStyleChoices, headerAlignClasses }) {
    const heroTitleRight = headerStyleChoices === 'hero_title_right';
    const heroPostClass = heroTitleRight ? 'justify-start' : 'justify-end';
    const heroMarginClasses = heroTitleRight ? 'md:ml-[48vw] lg:ml-[52vw]' : 'md:mr-[48vw] lg:mr-[52vw]';

    return (
        <div
            className={cx([
                isBasicHeader ? 'basic-header py-10' : 'hero-header ',
                'mx-auto flex h-full flex-col items-center md:flex-row',
                heroTitleRight && 'w-full',
                isPost ? `hidden ${heroPostClass} md:flex` : `container justify-end ${!heroTitleRight && 'md:justify-start'}`
            ])}>
            <div
                className={cx([
                    `flex flex-col ${headerAlignClasses} items-center text-center`,
                    isPost && heroMarginClasses,
                    isBasicHeader ? ' md:ml-[7.5%] 2xl:ml-0' : 'md:ml-[10%] md:max-w-[450px]',
                    heroTitleRight && 'justify-center md:mr-[5%]'
                ])}>
                {children}
            </div>
        </div>
    );
}
