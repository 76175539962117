import PostHeader from './HeaderTemplates/PostHeader';
import PageHeaderOptions from './HeaderTemplates/PageHeaderOptions';

export default function HeaderColorSettings({ headerColorProps, featuredImage, type, showBreadcrumbs, children, className }) {
    const {
        titleContainerBackgroundImage,
        titleContainerMobileBackgroundImage,
        headerBackgroundImageSettings,
        mobileHeaderBackgroundImageSettings,
        titleContainerBackgroundColor,
        titleSubtitleTextColor,
        titleSubtitleTextAlignment,
        headerContainerCssClassesCssSafelist,
        pageContainerClassCssSafelist,
        headerStyleChoices = 'normal',
        transparentHeader
    } = headerColorProps;
    const { node } = featuredImage || {};
    const isPost = type === 'post';
    const normalHeader = headerStyleChoices === 'normal';
    const isNormalPost = isPost && normalHeader;
    const isCategoryPage = type === 'category';
    const isBasicHeader = headerStyleChoices === 'basic';
    const headerBackgroundColor = !isPost && titleContainerBackgroundColor ? titleContainerBackgroundColor : `var(--color-primary);`;
    const categoryHeaderBgColor = titleContainerBackgroundColor || 'var(--color-white)';
    const textColor = isCategoryPage ? 'var(--color-black)' : 'var(--color-white)';

    const HeaderStylesOptions = ({ children }) => {
        const headerProps = {
            headerContainerClasses: headerContainerCssClassesCssSafelist,
            textAlignment: titleSubtitleTextAlignment,
            showBreadcrumbs,
            className,
            pageContainerClasses: pageContainerClassCssSafelist,
            transparentHeader,
            bgImg: titleContainerBackgroundImage,
            mobileBgImg: titleContainerMobileBackgroundImage,
            headerBackgroundImageSettings,
            headerStyleChoices,
            isBasicHeader,
            titleSubtitleTextColor
        };

        if (isPost) {
            return (
                <>
                    <PostHeader isNormalPost={isNormalPost} featuredImage={node} {...headerProps}>
                        {children}
                    </PostHeader>
                </>
            );
        } else {
            return (
                <PageHeaderOptions isCategoryPage={isCategoryPage} isBasicHeader={isBasicHeader} mobileBgImgSettings={mobileHeaderBackgroundImageSettings} {...headerProps}>
                    {children}
                </PageHeaderOptions>
            );
        }
    };

    return (
        <>
            <HeaderStylesOptions>{children}</HeaderStylesOptions>
            <style>{`
                        .headerBaseSettings {
                            color: ${titleSubtitleTextColor !== null ? titleSubtitleTextColor : textColor};
                            background-color: ${isCategoryPage ? categoryHeaderBgColor : headerBackgroundColor};
                        }
                    `}</style>
        </>
    );
}
