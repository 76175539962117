import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import cx from 'classnames';

export default function ImageLoader({ largeImgSrc, smallImgSrc, headerBackgroundImageSettings, altText, className: customClassNames }) {
    const { backgroundImageSize, backgroundPosition, mobileBackgroundImageSize, mobileBackgroundPosition } = headerBackgroundImageSettings || {};
    const { horizontal, vertical, customHorizontal, customVertical } = backgroundPosition || {};
    const { mobileHorizontal, mobileVertical, mobileCustomHorizontal, mobileCustomVertical } = mobileBackgroundPosition || {};
    const mobileHorizontalImagePosition = mobileHorizontal === 'custom' ? mobileCustomHorizontal : mobileHorizontal;
    const mobileVerticalImagePosition = mobileVertical === 'custom' ? mobileCustomVertical : mobileVertical;
    const horizontalImagePosition = horizontal === 'custom' ? customHorizontal : horizontal;
    const verticalImagePosition = vertical === 'custom' ? customVertical : vertical;

    const [blur, setBlur] = useState(true);
    const loadingImage = useRef();

    useEffect(() => {
        if (loadingImage?.current?.complete) setBlur(false);
        loadingImage?.current?.addEventListener('load', () => {
            setBlur(false);
        });
    }, []);

    return (
        <div className={`image-container relative aspect-video h-full w-full overflow-hidden`}>
            {smallImgSrc && (
                <Image
                    width={2000}
                    height={2000}
                    className={cx([
                        'placeholder-image absolute left-0 top-0 h-full w-full',
                        backgroundImageSize ? `object-${backgroundImageSize}` : `object-${mobileBackgroundImageSize}` || 'object-cover',
                        blur ? 'opacity-100' : 'opacity-0',
                        customClassNames
                    ])}
                    // sizes="(max-width: 767px) 100vw, 50vw"
                    alt={altText}
                    style={{
                        objectPosition:
                            horizontalImagePosition && verticalImagePosition
                                ? `${horizontalImagePosition} ${verticalImagePosition}`
                                : `${mobileHorizontalImagePosition} ${mobileVerticalImagePosition}`
                    }}
                    src={smallImgSrc}
                    priority
                />
            )}
            {largeImgSrc && (
                <Image
                    width={2000}
                    height={2000}
                    className={cx([
                        'real-image absolute left-0 top-0 h-full w-full',
                        backgroundImageSize ? `object-${backgroundImageSize}` : `object-${mobileBackgroundImageSize ? mobileBackgroundImageSize : 'cover'}` || 'object-cover',
                        customClassNames
                    ])}
                    ref={loadingImage}
                    alt={altText}
                    sizes="(max-width: 375px) 375px,
                       (max-width: 390px) 390px,
                       (max-width: 414px) 414px,
                       (max-width: 768px) 768px,
                       (max-width: 1024px) 1024px,
                       (max-width: 1200px) 1200px,
                       (max-width: 1536px) 1536px,
                       (max-width: 1980px) 1980px,
                       (max-width: 2048px) 2048px,
                       2560px"
                    style={{
                        objectPosition:
                            horizontalImagePosition && verticalImagePosition
                                ? `${horizontalImagePosition} ${verticalImagePosition}`
                                : `${mobileHorizontalImagePosition} ${mobileVerticalImagePosition}`
                    }}
                    src={largeImgSrc}
                    priority
                />
            )}
        </div>
    );
}
