// import Image from 'next/image';
import cx from 'classnames';
import PageHeaderOptions from './PageHeaderOptions';
import ImageLoader from '../../ImageLoader';

export default function PostHeader(props) {
    const { featuredImage, showBreadcrumbs, isNormalPost, headerContainerClasses, className, pageContainerClasses, transparentHeader, children } = props;
    const { sourceUrl, altText, lowRes } = featuredImage || {};
    const marginBottomClass = showBreadcrumbs ? 'mb-0' : 'mb-6';

    return (
        <>
            {!isNormalPost && (
                <PageHeaderOptions isPost {...props}>
                    {children}
                </PageHeaderOptions>
            )}
            <section className={cx(['header-image-container normalPostHeaderSettings relative mb-12', transparentHeader && 'bg-cover', className, headerContainerClasses])}>
                <div
                    className={cx([
                        'normal-post-featured-image mx-auto max-w-[800px] text-center',
                        !sourceUrl && 'mt-8',
                        !isNormalPost && 'block md:hidden',
                        // featuredImage && 'md:aspect-video'
                    ])}>
                    {featuredImage && <ImageLoader largeImgSrc={sourceUrl} smallImgSrc={lowRes} altText={altText} className={marginBottomClass} />}
                    <div className={cx(['page-header-container relative z-10 px-5 md:px-0', pageContainerClasses])}>{children}</div>
                </div>
            </section>
        </>
    );
}
