import dynamic from 'next/dynamic';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
// import Head from 'next/head';
import HeaderStyle from './settings/HeaderStyle';
// import Breadcrumbs from '../Breadcrumbs'
import Header from '@/components/HeaderTag';
// import SubCTA from '@/components/SubCTA';
// import LinkContainer from '@/components/SubCTA/LinkContainer';
import Link from 'next/link';

const SubCTA = dynamic(() => import('@/components/SubCTA'));
const LinkContainer = dynamic(() => import('@/components/SubCTA/LinkContainer'));

export default function PageHeader(props) {
    const {
        title,
        headerBackgroundImageSettings,
        mobileHeaderBackgroundImageSettings,
        headerContainerCssClassesCssSafelist,
        headerStyleChoices,
        overwriteTitle,
        showTitle,
        pageContainerClassCssSafelist,
        postSettings,
        subtitle,
        subtitleSize,
        titleContainerBackgroundColor,
        titleContainerBackgroundImage,
        titleContainerMobileBackgroundImage,
        titleSubtitleTextAlignment,
        titleSubtitleTextColor,
        transparentHeader,
        children,
        subcta,
        addCta,
        type,
        date,
        // categories,
        featuredImage,
        className
    } = props;

    const headerColorProps = {
        titleContainerBackgroundImage,
        titleContainerMobileBackgroundImage,
        headerBackgroundImageSettings,
        mobileHeaderBackgroundImageSettings,
        titleContainerBackgroundColor,
        titleSubtitleTextAlignment,
        titleSubtitleTextColor,
        headerContainerCssClassesCssSafelist,
        pageContainerClassCssSafelist,
        headerStyleChoices,
        transparentHeader
    };
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta || {};
    const { overwriteAuthorName, showBreadcrumbs, authorLink } = postSettings || {};
    const normalHeader = headerStyleChoices === 'normal';
    const normalPageHeader = normalHeader && type === 'page';
    const normalPostHeader = normalHeader && type === 'post';
    const isBasicHeader = headerStyleChoices === 'basic';
    const mobilePost = isMobile && type === 'post';

    return (
        <HeaderStyle className={className} showBreadcrumbs={showBreadcrumbs} featuredImage={featuredImage} type={type} headerColorProps={headerColorProps}>
            {showTitle && (
                <>
                    <Header
                        className={cx([
                            !normalPageHeader && !isBasicHeader && 'mb-4',
                            (normalPostHeader || mobilePost) && 'mt-12 md:mt-0 min-[820px]:mt-12',
                            isBasicHeader && 'mb-2 sm:text-7xl'
                        ])}
                        as="h1">
                        {overwriteTitle || title}
                    </Header>

                    {subtitle && (
                        <Header as={subtitleSize} className={cx(['leading-normal', 'px-0.5'])}>
                            {subtitle}
                        </Header>
                    )}
                </>
            )}
            {addCta && callToAction?.length > 0 && (
                <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                    {callToAction?.map((singleCta, index) => {
                        return (
                            <SubCTA
                                key={index}
                                type={singleCta?.ctaDisplay}
                                href={singleCta?.ctaLink?.url}
                                target={singleCta?.ctaTarget}
                                linkTarget={singleCta?.ctaLink?.target}
                                text={singleCta?.ctaLink?.title}
                                bgColor={singleCta?.ctaBgColor}
                                arrowSize="large"
                                textColor={singleCta?.ctaTextColor || 'white'}
                                modalContents={singleCta?.modalContents}
                                className={singleCta?.ctaCssClassesCssSafelist}
                            />
                        );
                    })}
                </LinkContainer>
            )}
            {postSettings?.overwriteAuthorName && (
                <p className={'mb-2 mt-3 px-0.5 text-sm'}>
                    {authorLink ? (
                        <>
                            by{' '}
                            <Link className="font-normal text-inherit underline hover:no-underline" href={authorLink}>
                                {overwriteAuthorName}
                            </Link>{' '}
                            | {date}
                        </>
                    ) : (
                        `by ${overwriteAuthorName} | ${date}`
                    )}
                </p>
            )}
            {/* {showBreadcrumbs && type === 'post' && <Breadcrumbs categories={categories} className={!normalHeader && 'md:justify-start'} />} */}
            {children}
        </HeaderStyle>
    );
}
