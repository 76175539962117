import cx from 'classnames';
import { getTextAlignmentHeader } from '@/utils/style';
import Normal from './Normal';
import Hero from './Hero';
import HeroTitleLeftOrRight from './HeroTitleLeftOrRight';
import ImageLoader from '../../ImageLoader';

export default function PageHeaderOptions(props) {
    const {
        children,
        textAlignment,
        isPost,
        isBasicHeader,
        isCategoryPage,
        transparentHeader,
        pageContainerClasses,
        bgImg,
        mobileBgImg,
        headerBackgroundImageSettings,
        mobileBgImgSettings,
        className,
        headerContainerClasses,
        headerStyleChoices
    } = props;
    const { sourceUrl: bgSourceUrl, altText: bgAltText, lowRes } = bgImg || {};
    const { sourceUrl: mobileBgSourceUrl, altText: mobileBgAltText, mobileLowRes } = mobileBgImg || {};
    const normalPageHeader = headerStyleChoices === 'normal';
    const headerAlignClasses = getTextAlignmentHeader(textAlignment) || '';
    const normalHeader = headerStyleChoices === 'normal';

    const PageHeaderStyles = props => {
        const { headerStyleChoices, children } = props;

        switch (headerStyleChoices) {
            case 'normal':
                return <Normal headerAlignClasses={headerAlignClasses}>{children}</Normal>;
            case 'hero':
                return (
                    <Hero isPost={isPost} headerAlignClasses={headerAlignClasses}>
                        {children}
                    </Hero>
                );
            case 'hero_title_right':
            case 'hero_title_left':
            case 'basic':
                return (
                    <HeroTitleLeftOrRight headerAlignClasses={headerAlignClasses} headerStyleChoices={headerStyleChoices} isBasicHeader={isBasicHeader} isPost={isPost}>
                        {children}
                    </HeroTitleLeftOrRight>
                );
            default:
                return <Normal headerAlignClasses={headerAlignClasses}>{children}</Normal>;
        }
    };

    return (
        <>
            <section
                className={cx([
                    'header-image-container headerBaseSettings relative px-4 lg:px-0',
                    !normalPageHeader && 'dark:bg-darkModeBg',
                    isPost && 'md:mb-12',
                    (isBasicHeader || isCategoryPage) && 'dark:bg-darkModeBg',
                    isCategoryPage && 'dark:text-white',
                    transparentHeader && 'bg-cover',
                    normalPageHeader && 'min-h-[220px]',
                    headerContainerClasses,
                    className
                ])}>
                <PageHeaderStyles headerStyleChoices={headerStyleChoices}>
                    <div className={cx(['page-header-container relative z-10 px-4 md:px-0', isPost && 'mt-[60vw] md:mt-0', pageContainerClasses])}>{children}</div>
                    <div className={cx([normalHeader && 'headerBackgroundImage absolute h-full w-full'])}>
                        {bgSourceUrl && (
                            <div
                                className={cx([mobileBgSourceUrl && 'hidden md:block', 'h-full w-full'])}
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                <ImageLoader largeImgSrc={bgSourceUrl} smallImgSrc={lowRes} altText={bgAltText} headerBackgroundImageSettings={headerBackgroundImageSettings} />
                            </div>
                        )}

                        {mobileBgSourceUrl && !isPost && (
                            <div className="block md:hidden" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                <ImageLoader
                                    largeImgSrc={mobileBgSourceUrl}
                                    smallImgSrc={mobileLowRes}
                                    altText={mobileBgAltText}
                                    headerBackgroundImageSettings={mobileBgImgSettings}
                                />
                            </div>
                        )}
                    </div>
                </PageHeaderStyles>
            </section>
        </>
    );
}
