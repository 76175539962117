import { useAppTheme } from './GlobalAppTheme';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

export default function Header({ as, children, ...props }) {
    const theme = useAppTheme();

    const HTag = as || 'h1';
    const { className } = props;
    const { desktopFonts, mobileFonts } = theme;
    let fonts = {};

    if (isMobile) fonts = mobileFonts;
    else fonts = desktopFonts;

    const { fontFamily, fontSize, weight } = fonts?.filter(font => font?.tag === as)?.[0] || '';
    return <HTag className={cx(['', `font-${fontFamily} ${weight}`, fontSize && `text-${fontSize}`, className])} dangerouslySetInnerHTML={{ __html: children }}></HTag>;
}
