import cx from 'classnames';

export default function Hero({ headerAlignClasses, children, isPost }) {
    return (
        <div
            className={cx([
                `hero-header mx-auto flex h-full w-full flex-col items-center justify-center text-center md:max-w-[450px] ${headerAlignClasses}`,
                isPost && 'hidden md:flex'
            ])}>
            {children}
        </div>
    );
}
